<template>
    <div class="topic-item">
        <div class="topic-title">
            <p class="title" v-html="formatTitle(item.title)"></p>
        </div>
        <div class="topic-options">
            <el-input type="textarea" placeholder="请输入答案" v-model="text" @input="listenInput"></el-input>
        </div>
    </div>
</template>

<script>
export default {
    name: "answerItem",
    props: {
        index: Number,
        item: Object,
    },
    mounted() {
        let stu_answer = JSON.parse(this.item.stu_answer);
        if (stu_answer.length) {
            this.text = stu_answer[0];
        }
    },
    data() {
        return {
            text: ""
        }
    },
    methods: {
        formatTitle(val) {
            return `${this.index + 1}、【问答题】${val}`;
        },
        listenInput(val) {
            let data = {id: this.item.school_theory_topic_id, label: JSON.stringify([val])};
            if (val.trim().length === 0) {
                data.label = JSON.stringify([]);
            }
            this.$emit('change', data);
        }
    }

}
</script>

<style scoped lang="scss">
.topic-item {
    .topic-title {
        width: 100%;
        min-height: 50px;
        background-color: #F2F3FA;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        &::v-deep .title {
            display: inline-block;
            width: calc(100% - 30px);
            font-size: 14px;
            color: #343441;
            p {
                display: inline-block;
                padding: 0;
                margin: 0;
            }
        }
    }
    .topic-options {
        background-color: #FFF;
        padding: 13px 20px 19px 70px;
        &::v-deep {
            .el-textarea__inner {
                height: 200px;
            }
        }
    }
}
</style>