<template>
    <div class="topic-item">
        <div class="topic-title">
            <p class="title" v-html="formatBlanks(item.title)"></p>
        </div>
        <div class="topic-options">
            <div class="input-blank-item" v-for="(item, key) in blanks" :key="key">
                <span class="number">({{key + 1}})</span><el-input class="blank-input" v-model="item.value" @input="addListenValue"></el-input>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "fillblankItem",
    props: {
        item: Object,
        index: Number
    },
    data() {
        return {
            blanks: []
        }
    },
    mounted() {
        let stu_answer = JSON.parse(this.item.stu_answer);
        this.blankCount(this.item.title, stu_answer);

    },
    methods: {
        formatBlanks(val) {
            let title = val;
            let result = val.match(/_+/g);
            result.map((val, index) => {
                title = title.replace(/_+/, `<span class="blank-item-one">（${index+1}）</span>`);
            });
            return `${this.index + 1}、【填空题】${title}`;
        },
        blankCount(title, stu_answer=[]) {
            let pattern = /_+/g;
            let result = title.trim().match(pattern);
            if (result) {
                result.map((item, index) => {
                    let tmp = {value: ''};
                    if (stu_answer.length) {
                        tmp.value = stu_answer[index]
                    }
                    this.blanks.push(tmp);
                });
            }
        },
        addListenValue() {
            let allSpace = true;
            this.blanks.map((item) => {
                if (item.value.trim().length !== 0) {
                    allSpace = false;
                }
            });
            let data = {id: this.item.school_theory_topic_id, label: this.blanks};
            if (allSpace) {
                data.label = [];
            }
            this.$emit('change', data);
        }
    }

}
</script>

<style scoped lang="scss">
.topic-item {
    .topic-title {
        width: 100%;
        height: 50px;
        background-color: #F2F3FA;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        &::v-deep .title {
            display: inline-block;
            width: calc(100% - 30px);
            font-size: 14px;
            color: #343441;
            p {
                display: inline-block;
            }
        }
        &::v-deep span.blank-item-one {
            padding: 0px 10px;
            border-bottom: 1px solid #000;
        }
    }
    .topic-options {
        background-color: #FFF;
        padding: 13px 0 19px 70px;
        .el-radio-item {
            margin-bottom: 20px;
        }
        .input-blank-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 300px;
            margin: 10px;
            span.number {
                margin-right: 10px;
            }
        }
    }
}
</style>