<template>
    <div class="topic-item">
        <div class="topic-title">
            <p class="title" v-html="formatTitle(item.title)"></p>
        </div>
        <div class="topic-options">
            <el-checkbox-group v-model="checked" @change="handleEvt">
                <div class="el-checked-item" v-for="(val, key) in formatOptions(item.options)" :key="key">
                    <el-checkbox :label="key">{{formatIndex(key)}}、{{val}}</el-checkbox>
                </div>
            </el-checkbox-group>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common";

export default {
    name: "multipleItem",
    props: {
        item: Object,
        value: String,
        index: Number,
    },
    data() {
        return {
            checked: []
        }
    },
    mounted() {
        let stu_answer = JSON.parse(this.item.stu_answer);
        if (stu_answer.length) {
            this.checked = stu_answer;
        }
    },
    methods: {
        formatIndex(index) {
            return common.numberToAlphabet(index);
        },
        formatTitle(val) {
            return `${this.index + 1}、【多选题}】${val}`;
        },
        formatOptions(options) {
            return JSON.parse(options);
        },
        handleEvt(val) {
            this.$emit('change', {
                id: this.item.school_theory_topic_id,
                label: JSON.stringify(val)
            })
        }
    }
}
</script>

<style scoped lang="scss">
.topic-item {
    .topic-title {
        width: 100%;
        background-color: #F2F3FA;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        &::v-deep .title {
            display: inline-block;
            width: calc(100% - 30px);
            font-size: 14px;
            color: #343441;
            p {
                display: inline-block;
            }
        }
    }
    .topic-options {
        background-color: #FFF;
        padding: 13px 0 19px 70px;
        .el-radio-item {
            margin-bottom: 20px;
        }
        .el-checked-item {
            margin-bottom: 20px;
            &::v-deep {
                .el-checkbox__input {
                    &.is-checked {
                        .el-checkbox__inner {
                            background-color: #1122D8;
                            border-color: #1122D8;
                        }
                        &+.el-checkbox__label {
                            color: #1122D8;
                        }
                    }
                }
            }
        }
    }
}
</style>