<template>
    <div class="topic-item">
        <div class="topic-title">
            <p class="title" v-html="formatTitle(item.title)"></p>
        </div>
        <div class="topic-options">
            <el-radio-group v-model="radio" @change="handleEvt">
                <div class="el-radio-item" v-for="(val, key) in formatOptions(item.options)">
                    <el-radio :label="key" :key="key"><span v-if="isShowAl">{{formatIndex(key)}}、</span>{{val}}</el-radio>
                </div>
            </el-radio-group>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common";
export default {
    name: "singleItem",
    props: {
        item: Object,
        value: String,
        index: Number,
        label: {
            type: String,
            default: '单选题'
        },
        isShowAl: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            radio: ''
        }
    },
    mounted() {
        let stu_answer = JSON.parse(this.item.stu_answer);
        if (stu_answer.length) {
            this.radio = stu_answer[0];
        }
    },
    methods: {
        formatIndex(index) {
            return common.numberToAlphabet(index);
        },
        formatTitle(val) {
            if (this.item.highest_score) {
                return `${this.index + 1}、【${this.label}】${val}` + '（'+ Number(this.item.highest_score) +'分）';
            }
            return `${this.index + 1}、【${this.label}】${val}`;
        },
        formatOptions(options) {
            return JSON.parse(options);
        },
        handleEvt(val) {
            this.$emit('change', {
                id: this.item.school_theory_topic_id,
                label: JSON.stringify([val])
            })
        }
    }
}
</script>

<style scoped lang="scss">
.topic-item {
    .topic-title {
        width: 100%;
        height: 50px;
        background-color: #F2F3FA;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        &::v-deep .title {
            display: inline-block;
            width: calc(100% - 30px);
            font-size: 14px;
            color: #343441;
            p {
                display: inline-block;
            }
        }
    }
    .topic-options {
        background-color: #FFF;
        padding: 13px 0 19px 70px;
        .el-radio-item {
            margin-bottom: 20px;
        }
    }
    .el-radio-item {
        &::v-deep {
            .el-radio__input {
                &.is-checked {
                    .el-radio__inner {
                        background-color: #1122D8;
                        border-color: #1122D8;
                    }
                    &+.el-radio__label {
                        color: #1122D8;
                    }
                }
            }
        }
    }
}

</style>